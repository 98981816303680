:root {
  --bg: #740a7e;
  /* --bg: rgb(102, 15, 110); */
  --primary-color: rgb(0, 0, 0);
  --secondary-color: #ff7300;
  --secondary-color-transparent: #ff730030;
  --shadow: 0px 0px 12px var(--secondary-color);
  --dark-shadow: 2px 2px 8px black;
  --header-font: 'Griffy', cursive;
  --body-font: sans-serif;
}

* {
  color: white;

  -webkit-tap-highlight-color: var(--secondary-color-transparent);
    -webkit-touch-callout: var(--secondary-color-transparent);
    -webkit-user-select: var(--secondary-color-transparent);
    -khtml-user-select: var(--secondary-color-transparent);
    -moz-user-select: var(--secondary-color-transparent);
    -ms-user-select: var(--secondary-color-transparent);
    user-select: var(--secondary-color-transparent);
}

html {
  min-height: 100vh;
  background-color: var(--primary-color);

  /* background-image: url(./images/spider-web-background.jpg);
  background-color: var(--bg);
  background-blend-mode: darken;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
}

body {
  font-family: var(--body-font);
  background-image: url(./images/spider-web-background.jpg);
  background-color: var(--bg);
  background-blend-mode: darken;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  padding-top: .1px;
}

/* Reused elements */

.page-header {
  margin: 1rem auto 1rem auto;
  color: var(--secondary-color);
  font-family: var(--header-font);
  font-weight: 400;
  background-color: var(--primary-color);
  max-width: fit-content;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: var(--shadow);
}


.card-hover {
  transition: 250ms;
}

.card-hover:hover {
  height: 8rem;
}

.bottom-margin {
  height: 4.5rem
  /* background-color: black; */
}

footer {
  position: fixed;
  bottom: 0px;
  background-color: var(--primary-color);
  height: 4.5rem;
  width: 100%;

  border-top: solid var(--secondary-color) 2px;
  
  display: flex;
  justify-content: flex-end;
}

.footer-buttons {
  display: flex;
  width: 40%;
}

.back-button {
  /* height: 100%; */
  width: 50%;

  color: var(--secondary-color);
  font-weight: 900;
  border-left: 2px solid var(--secondary-color);
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 1.75em;
}

.back-button svg path{
  color: var(--secondary-color);
}

.footer-title {
  width: 60%;
  height: 100%;
  text-align: center;
  line-height: 4.5rem;

  font-size: 1.5em;
  color: var(--secondary-color);
  font-family: var(--header-font);
  font-weight: 400;

  text-decoration: none;
}

.back-button:hover {
  cursor: pointer;
}

.button-flip:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

/* Landing page styling */

.landing-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  /* align-items: center; */
  /* justify-content: center; */
}

.primary-landing-container {
  margin: auto;
  width: 100%;
  max-width: 800px;
  min-height: 16rem;

  background-image: url('./images/landing-background.jpg');
  background-color: rgb(182, 181, 181);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  border-top: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
}

.minor-landing-items {
  margin-top: -2px;
  
  width: 100%;
  display: flex;
  /* gap: 2px; */
}

.minor-landing-items a {
  width: 50%;
  height: 13rem;
  background-color: var(--primary-color);
  border-top: solid var(--secondary-color) 2px;
  border-bottom: solid var(--secondary-color) 2px;

  background-color: black;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  position: relative;
}

.minor-landing-items a:last-child {
  border-left: solid var(--secondary-color) 2px;
}

/* .minor-landing-items a div {
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  line-height: 150%;
  border-radius: 0 0 8px 8px;

  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 8px;
  font-weight: bolder;

  padding-top: 3px;
  padding-bottom: 3px;

} */

.landing-button {
  /* height: 20%;
  width: 40%; */

  height: 3rem;
  width: 9rem;

  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 8px;
  font-weight: bolder;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.poison-apple-featured {
  background-image: url(./images/poison-apple.jpg);
}

.pumpkin-spice-white-russian-featured {
  background-image: url(./images/pumpkin-spice-white-russian.jpg);
}

.witches-brew-featured {
  background-image: url(./images/witches-brew.jpg);
}

.zombie-smash-featured {
  background-image: url(./images/zombie-smash.jpg);
}


/* Error Page */

.error-container {
  width: 90%;
  height: 100vh;
  max-width: 40rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.error-button-container {
  display: flex;
  gap: 1rem;
}

.error-button {
  height: 3rem;
  width: 10rem;


  background-color: var(--primary-color);
  color: var(--secondary-color);
  text-decoration: none;
  border: 2px solid var(--secondary-color);
  border-radius: 8px;
  font-weight: bolder;

  
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Drinks Page styling */

.drinks-container {
  max-width: 900px;
  margin: auto;
}

.drinks-container a{
  text-decoration: none;
}

.drinks-items {
  background-color: var(--primary-color);
  border: 2px solid var(--secondary-color);
  border-left: none;
  border-right: none;
  margin-bottom: -2px;

  list-style: none;
  width: 100%;
  height: 6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url('./images/landing-background.jpg');
  background-color: #97979794;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.drinks-items div{
  background-color: var(--primary-color);
  color: var(--secondary-color);
  min-width: 30px;
  padding: 5px 10px;
  border: 2px solid var(--secondary-color);
  border-radius: 8px;
  text-align: center;
}

.drinks-container a:last-child {
  margin-bottom: 6rem;
}

.all {
  background-position: center center;
}

.vodka {
  background-image: url('./images/vodka.jpg');
}

.tequila {
  background-image: url('./images/tequila.jpg');
}

.whiskey {
  background-image: url('./images/scotch-whiskey.jpg');
  background-position: center center;
}

.halloween {
  background-image: url('./images/halloween.jpg');
  background-position: center center;
}

.gin {
  background-image: url('./images/gin.jpg');
  background-position: top left;
}

.rum {
  background-image: url('./images/rum.jpg');
  background-position: center center;
}

.champagne {
  background-image: url('./images/champagne.jpg');
  background-position: center center;
}

/* Drink :category: page */

.category-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.category-drink-container {
  width: 45%;
  height: 40vh;
  max-height: 15rem;
  margin-bottom: 1rem;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  position: relative;
  border-radius: 8px;
  box-shadow: var(--dark-shadow);
  transition: ease-in-out 200ms;
}

.category-items {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
  line-height: 150%;
  border-radius: 0 0 8px 8px;
  
}

/* .category-drink-container:hover {
  width: 47%;
} */


.category-items div {
  color: var(--secondary-color);
  font-size: 1.33em;
  text-shadow: -1px -1px 1px black, -1px 1px 1px black, 1px 1px 1px black, 1px -1px 1px black;
}

.category-images {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.drink-image-container {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: auto;
  background-color: var(--primary-color);
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  box-shadow: var(--dark-shadow);
}

.drink-image {
  max-width: calc(100% - 2rem);
  margin-bottom: 1rem;
  border-radius: 5px;
  align-self: center;
  /* border: solid 5px var(--secondary-color); */
}

/* .drink-text-container {
  margin: 0 5% 0 5%;
} */

.drink-desc {
  align-self: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.ingredient-list {
  list-style: square;
  margin: 0 1rem 1rem 1rem;
  padding: none;
}

.ingredient-list h3{
  text-align: center;
}

.ingredient-item {
  display: flex;
  /* justify-content: space-between; */
}

.ingredient-item span {
  display: flex;
  flex: 1;
  align-items: center;
}

.ingredient-item span::before{
  content:'';
  background: repeating-linear-gradient(to right, currentColor, currentColor 2px, transparent 2px, transparent 4px);
  height: 1px;
  flex-grow: 1;
  display: inline-block;
  margin-top: .6em;
  margin-left: 3px;
}

.gradient-bottom-border-l {
  width: 100%;
  border-block-end: 5px solid;
  border-image-source: 
  radial-gradient(circle at bottom left, 
  var(--secondary-color), 
  transparent 90%);
  border-image-slice: 1;
}

.gradient-bottom-border-r {
  width: 100%;
  border-block-end: 5px solid;
  border-image-source: 
  radial-gradient(circle at bottom right, 
  var(--secondary-color), 
  transparent 90%);
  border-image-slice: 1;
}

